@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/general-sans');

body {
  margin: 0;
  padding:0;
  overflow-x: hidden;
  font-family: 'General Sans', sans-serif;
}
.primary-btn{
    @apply text-white rounded-full py-3 px-10 bg-primary text-sm font-medium
}
.heading-text{
  @apply text-lg font-medium text-primary
}
.res-cont{
  @apply w-full m-0 px-5 xl:px-28 lg:px-28 md:px-28 md:py-12 py-6
}
.img-cont{
  @apply w-[300px] h-[300px]
}
.doubled-grid{
  @apply grid grid-cols-1 md:grid-cols-2 md:gap-24 items-start
}
.basic-info{
  @apply md:text-sm text-xs md:font-medium font-normal leading-6
}
/* .hero-home{
  background-image: url("./assets/images/hero-bg.png");
} */
.sg-bg{
  /* width: 200px; */
background-image: url("./assets/images/sgbg.png");
background-repeat: no-repeat;
background-size: contain;
}
.gradient-bg{
background-image: url("./assets/images/gradientbg.png");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
}
.waitbody{
  background:  url("./assets/images/half-moon.png") no-repeat bottom center;
  background-size: contain;
}
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: "100%";
}
.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #FF3838 transparent rgb(233, 69, 9) transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



